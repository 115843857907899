/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import ReminderConfirmationModal from './ReminderConfirmationModal';
import {
  calculateDayDifference,
  isLastDateOfMonth,
  RecurringOptions,
  reminder,
  reminderStatus,
} from './ReminderStatic';
import { fetchUsers } from '../../../../axiosClient/authApi';
import { getDownloadUrl } from '../../../../axiosClient/contractsApi';
import { createReminder, editReminder } from '../../../../axiosClient/reminders';
import ControlledCheckbox from '../../../../components/ControlledCheckbox';
import DatePickerElement from '../../../../components/ControlledDatePicker';
import ControlledTextField from '../../../../components/ControlledTextField';
import CustomChip from '../../../../components/CustomChip';
import NotepadComponent from '../../../../components/NotepadComponent';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import RISelectComponent from '../../../../components/SelectComponent';
import SelectElement from '../../../../components/SelectElement';
import { download_file } from '../../../../hooks/Helper';
import FilterInput from '../../../Reminder/FilterInput';
import UploadSupportFile from '../UploadSupportFile';
import ViewDocumentDrawer from '../ViewDocumentDrawer';

interface Props {
  onClose: VoidFunction;
  contractData: any;
  reminderEditData?: any;
  setViewOnly?: Dispatch<SetStateAction<boolean>>;
  viewOnly?: boolean;
  user_data?: any;
  sectionName?: string;
  clauseItem?: any;
}

const ReminderForm: React.FC<Props> = ({
  onClose,
  contractData,
  reminderEditData,
  setViewOnly,
  viewOnly = false,
  user_data,
  sectionName,
  clauseItem,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isNotLastDateOfMonth, setIsNotLastDateOfMonth] = useState(false);
  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(false);
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);

  const handleCloseSupportDoc = () => {
    setIsShowUploadField(false);
  };

  const defaultReminderIds = reminder
    ?.filter((reminderOption) => reminderOption.name !== 'Don’t remind me')
    ?.map((reminderOption) => reminderOption.name);

  const reminderOption = useMemo(() => {
    const calculatedReminderIds = reminderEditData?.remind_when || [];

    return (
      defaultReminderIds?.filter((id) =>
        calculatedReminderIds?.some((reminder: any) => id?.startsWith(`${reminder} `))
      ) || []
    );
  }, [reminderEditData?.remind_when, defaultReminderIds]);

  const methods = useForm({
    defaultValues: {
      ...reminderEditData,
      users: reminderEditData?.users?.map((user: any) => user?.id),
      due_date: reminderEditData ? dayjs(reminderEditData?.due_date) : '',
      support_documents: reminderEditData?.support_documents?.[0]?.id,
      remind_when:
        reminderEditData?.due_date && reminderEditData?.remind_when?.length > 0
          ? reminderOption
          : ['Don’t remind me'],
      note: reminderEditData?.note
        ? reminderEditData?.note
        : reminderEditData?.section_name
          ? `<h2>${reminderEditData.section_name}</h2><p>${reminderEditData.clause}</p>`
          : '',
      show_recurring_option: !!reminderEditData?.recurring_type,
    },
  });

  const { handleSubmit, control, reset, setValue, watch, setError, clearErrors } =
    methods;
  const queryClient = useQueryClient();
  const selectedApprovers = watch('users');
  const selectedRemindMe = watch('remind_when');
  const dueDate = watch('due_date');
  const validTill = watch('valid_till');
  const showRecurring = watch('show_recurring_option');
  const recurringType = watch('recurring_type');
  const daysValue = watch('days_value');

  useEffect(() => {
    setValue('contract', contractData?.title);
  }, [contractData, setValue]);

  useEffect(() => {
    if (sectionName) {
      const note = `<h2>${sectionName}</h2><p>${clauseItem.original_context || clauseItem.sentence}</p>`;
      setValue('note', note);
    }
  }, [sectionName, reminderEditData]);

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await fetchUsers(),
    select: (response) => response.results as any,
  });

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
      reset();
      enqueueSnackbar('Obligation marked successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to mark Obligation!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Obligation updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Obligation!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: downloadFile } = useMutation({
    mutationFn: getDownloadUrl,
    onSuccess: (response: any) => {
      download_file(response);
    },
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ['get_contract_data_by_id'] });
  };

  const isReadOnly = useMemo(
    () =>
      reminderEditData?.id &&
      (reminderEditData?.created_by?.id !== user_data?.id || viewOnly),
    [reminderEditData, user_data?.id, viewOnly]
  );

  const disabledFields = useMemo(
    () =>
      reminderEditData?.id &&
      (reminderEditData?.created_by?.id !== user_data?.id || viewOnly),
    [reminderEditData, user_data?.id, viewOnly]
  );

  const disableNotes = useMemo(() => {
    if (sectionName || reminderEditData?.section_name) {
      return true;
    } else if (reminderEditData?.note && viewOnly) {
      return true;
    } else {
      return false;
    }
  }, [sectionName, reminderEditData, viewOnly]);

  useEffect(() => {
    if (recurringType && !viewOnly) {
      setValue('days_value', '');
      setValue('duration', '');
      setValue('valid_till', '');
    }
  }, [recurringType]);

  /// when to remind option disable logic
  const formattedDueDate = useMemo(
    () => (dayjs(dueDate).isValid() ? dayjs(dueDate).format('YYYY-MM-DD') : null),
    [dueDate]
  );

  const dayDiff = calculateDayDifference(formattedDueDate);

  const disableOptionCondition = (option: any) => {
    const optionDayValue = parseInt(option?.name?.split(' ')[0], 10);
    if (selectedRemindMe?.includes('Don’t remind me')) {
      return option?.name !== 'Don’t remind me';
    }
    if (selectedRemindMe?.length > 0 && option?.name === 'Don’t remind me') {
      return true;
    }
    return optionDayValue > dayDiff;
  };

  useEffect(() => {
    if (
      (formattedDueDate && !reminderEditData?.due_date) ||
      (reminderEditData?.due_date &&
        formattedDueDate &&
        reminderEditData.due_date !== formattedDueDate)
    ) {
      setValue('remind_when', []);
    }
  }, [formattedDueDate, setValue, reminderEditData?.due_date]);

  const handleCloseViewDrawer = () => {
    setOpenViewDrawer(false);
    setViewDocumentId('');
  };

  // Custom validation for "valid_till"
  const validateValidTill = useCallback(
    (value: any) => {
      if (formattedDueDate && value) {
        const dueMonth = dayjs(formattedDueDate).month();

        const validTillMonth = dayjs(value).month();

        const notLastDate = !isLastDateOfMonth(value);

        if (validTillMonth === dueMonth && recurringType === 'last_day_of_month') {
          if (notLastDate) {
            setError('valid_till', {
              type: 'manual',
              message: 'Select the last date of this month',
            });
            return false;
          }
        }
      }
      clearErrors('valid_till');
      return true;
    },
    [recurringType, formattedDueDate]
  );

  const isValidTill = useMemo(() => validateValidTill(validTill), [validTill]);

  useEffect(() => setIsNotLastDateOfMonth(isValidTill), [isValidTill]);

  const onSubmit = (data: any) => {
    if (!isNotLastDateOfMonth && recurringType === 'last_day_of_month') {
      enqueueSnackbar('Select the last date of this month', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    }

    const validReminders = data?.remind_when?.filter(
      (reminderOption: string) => reminderOption !== 'Don’t remind me'
    );

    const remindWhenNumber = validReminders
      ?.map((reminder: any) => {
        const match = reminder?.match(/\d+/);
        return match ? parseInt(match[0], 10) : null;
      })
      ?.filter((num: any) => num !== null);

    let payload = {
      ...data,
      support_documents: data?.support_documents ? [data?.support_documents] : [],
      contract: contractData?.id,
      app: 'Umbrella',
      remind_when: remindWhenNumber ? remindWhenNumber : [],
      due_date: formattedDueDate,
      ...(sectionName && {
        section_name: sectionName,
        clause: clauseItem.sentence,
      }),
      ...(recurringType && {
        valid_till: dayjs(data?.valid_till).isValid()
          ? dayjs(data?.valid_till).format('YYYY-MM-DD')
          : null,
      }),
      ...(recurringType === 'day_of_month' && {
        days_value: data?.days_value,
      }),
      ...(recurringType === 'recreate_every' && {
        duration: data?.duration,
        days_value: data?.days_value,
      }),
    };

    if (reminderEditData?.id) {
      delete payload['id'];
      delete payload['comments'];
      delete payload['created'];
      delete payload['draft'];
      delete payload['external_user'];
      delete payload['checklist'];
      delete payload['draft_item'];
      delete payload['deviation'];

      payload = {
        id: reminderEditData?.id,
        body: {
          ...payload,
        },
      };
      update_reminder(payload);
    } else {
      createReminderMutation(payload);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: '700px', padding: '30px 10px 10px' }}>
          <Grid container gap={2}>
            <Grid size={{ sm: 12 }}>
              <ControlledTextField
                name="name"
                control={control}
                label="Obligation Name"
                fullWidth
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Stack width="100%" spacing={2} direction="row">
              <DatePickerElement
                name="due_date"
                control={control}
                label="Due Date"
                required
                disablePast
                sx={{ flex: 1 }}
                disabled={isReadOnly}
              />
              <Stack flex={1}>
                <FilterInput
                  name="remind_when"
                  control={control}
                  label="Remind When"
                  options={reminder}
                  labelKey="name"
                  readOnly={isReadOnly || !dueDate}
                  isMultiselect={true}
                  valueKey="name"
                  renderCustomComponent={(value: any) => (
                    <CustomChip key={value.id} label={value?.name || ''} />
                  )}
                  disableOptionCondition={disableOptionCondition}
                />
                <Typography
                  variant="caption"
                  sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '3px 14px 0' }}
                >
                  Select the due date first to enable only valid options
                </Typography>
              </Stack>
            </Stack>
            {!reminderEditData?.parent && (
              <FormControl fullWidth>
                <ControlledCheckbox
                  control={control}
                  name="show_recurring_option"
                  label="Create Reminder For Recurring Due Dates"
                  disabled={viewOnly || isReadOnly}
                  icon={<AddCircleOutlineRoundedIcon />}
                  checkedIcon={<RemoveCircleOutlineIcon />}
                  labelProps={{ sx: { color: 'rgb(109, 38, 76)' } }}
                />
                {showRecurring && (
                  <Stack spacing={2} marginBottom="0.5rem">
                    <RadioButtonGroup
                      row
                      required
                      name="recurring_type"
                      options={RecurringOptions}
                      valueKey="value"
                      control={control}
                      disabled={isReadOnly}
                    />

                    <Stack spacing={1}>
                      <Stack direction="row" spacing={1.5} alignItems="start">
                        {recurringType === 'day_of_month' && (
                          <ControlledTextField
                            name="days_value"
                            control={control}
                            disabled={isReadOnly}
                            label="Enter value"
                            type="number"
                            helperText={
                              daysValue > 28
                                ? 'This value may not be available in every month'
                                : ''
                            }
                            validation={{
                              validate: (value) => {
                                if (value > 31 || value < 1) {
                                  return 'This value is invalid';
                                }
                                if (value > 28) {
                                  return true;
                                }
                                return true;
                              },
                            }}
                          />
                        )}
                        {recurringType === 'recreate_every' && (
                          <Stack
                            direction="row"
                            alignItems="start"
                            width="50%"
                            spacing={1.5}
                          >
                            <ControlledTextField
                              name="days_value"
                              control={control}
                              type="number"
                              label="Enter value"
                              disabled={isReadOnly}
                            />
                            <Controller
                              name="duration"
                              control={control}
                              render={({ field }) => (
                                <FormControl sx={{ width: '50%' }}>
                                  <InputLabel>Duration</InputLabel>
                                  <Select
                                    {...field}
                                    label="Duration"
                                    variant="outlined"
                                    disabled={isReadOnly}
                                  >
                                    {['Days', 'Months', 'Years'].map((duration) => (
                                      <MenuItem
                                        key={duration}
                                        value={duration.toLowerCase()}
                                      >
                                        {duration}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Stack>
                        )}
                        {recurringType && (
                          <DatePickerElement
                            name="valid_till"
                            control={control}
                            label="Valid Till"
                            minDate={dueDate}
                            required
                            disablePast
                            sx={{ flex: 1 }}
                            disabled={isReadOnly}
                            inputProps={{
                              helperText:
                                !!validTill && !isValidTill
                                  ? 'Select the last date of this month'
                                  : '',
                              error: !!validTill && !isValidTill,
                            }}
                          />
                        )}
                      </Stack>
                      {reminderEditData?.recurring_type !== recurringType &&
                        reminderEditData?.children_count > 0 && (
                          <Typography
                            variant="caption"
                            color="rgba(0, 0, 0, 0.7)"
                            fontWeight={600}
                          >
                            Existing selection includes &quot;
                            {reminderEditData?.children_count}{' '}
                            {reminderEditData?.children_count === 1
                              ? 'Recurring Reminder'
                              : 'Recurring Reminders'}
                            &quot;. Switching to another recurring type will trigger
                            future recurring reminders based on the new selection.
                          </Typography>
                        )}
                    </Stack>
                  </Stack>
                )}
              </FormControl>
            )}
            <SelectElement
              fullWidth
              name="status"
              control={control}
              label="Status"
              options={reminderStatus}
              labelKey="name"
              disabled={viewOnly}
            />

            <Stack width="100%" spacing={2}>
              <Typography fontWeight={'700'}>Share this Obligation</Typography>
              <RISelectComponent
                readOnly={isReadOnly}
                name="users"
                control={control}
                label="Select Users"
                options={approversData}
                loading={approversLoading}
                isMultiselect={true}
                renderCustomComponent={(value: any, props) => (
                  <CustomChip
                    icon={<PersonOutlineOutlinedIcon />}
                    key={value?.id}
                    label={value?.name || ''}
                    {...props}
                  />
                )}
                textFieldProps={{
                  helperText: 'you can select multiple users',
                }}
              />
              <NotepadComponent name="note" control={control} readOnly={disableNotes} />
            </Stack>
            {viewOnly && reminderEditData?.support_documents?.length > 0 ? (
              <Stack mt={6} spacing={1} width="100%">
                <Typography fontWeight={'700'}>Support document</Typography>
                {reminderEditData?.support_documents?.map((item: any, index: number) => (
                  <Stack
                    key={index}
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontWeight={600}>{item?.file_name}</Typography>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <IconButton onClick={() => downloadFile(item?.id)}>
                        <FileDownloadOutlinedIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setViewDocumentId(item?.id);
                        }}
                      >
                        <VisibilityOffIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Stack mt={6} width="100%" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography fontWeight={'700'} mt={0}>
                    Associated document
                  </Typography>
                  {!viewOnly && (
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<FileUploadOutlinedIcon />}
                      onClick={() => setIsShowUploadField(true)}
                      disabled={disabledFields}
                    >
                      Upload Support document
                    </Button>
                  )}
                </Stack>
                <RISelectComponent
                  readOnly={isReadOnly}
                  name="support_documents"
                  control={control}
                  label="Support document"
                  valueKey="id"
                  labelKey="file_name"
                  options={contractData?.support_document}
                  renderCustomComponent={(value: any, props) => (
                    <Chip
                      style={{
                        background: '#FFECF1',
                        padding: '15px 10px',
                        borderRadius: '5px',
                        color: '#6D264C',
                      }}
                      icon={<FolderOutlinedIcon style={{ color: '#6D264C' }} />}
                      label={value?.title}
                      {...props}
                    />
                  )}
                  textFieldProps={{
                    helperText: 'Select or upload support document',
                  }}
                />
              </Stack>
            )}
            <Stack spacing={0} width="100%">
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Stack direction="row">
                  {!reminderEditData?.id && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: '40px', width: 'max-content' }}
                      disabled={!selectedApprovers?.length}
                    >
                      Mark an Obligation
                    </Button>
                  )}

                  {reminderEditData?.id && viewOnly && (
                    <Button
                      variant="contained"
                      sx={{ height: '40px' }}
                      onClick={() => setViewOnly?.(false)}
                    >
                      Edit
                    </Button>
                  )}

                  {reminderEditData?.id && !viewOnly && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: '40px' }}
                      disabled={!selectedApprovers?.length}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                    sx={{ height: '40px' }}
                  >
                    Cancel
                  </Button>
                </Stack>

                {reminderEditData?.id && (
                  <Stack justifyContent="center" width="100%" alignItems="end">
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<NotificationsIcon />}
                      disabled={disabledFields}
                      onClick={() => setOpenForm(true)}
                    >
                      Send an Obligation now
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </form>

      {isShowUploadField && (
        <UploadSupportFile
          open={isShowUploadField}
          onClose={handleCloseSupportDoc}
          handleRefresh={handleRefresh}
          allContracts={contractData}
          type="Support Document"
        />
      )}
      {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )}
      {openForm && (
        <ReminderConfirmationModal
          open={openForm}
          handleClose={() => setOpenForm(false)}
          reminderData={reminderEditData}
          onClose={onClose}
        />
      )}
    </FormProvider>
  );
};

export default ReminderForm;
