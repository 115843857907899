/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import { ArticleOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, Divider, IconButton, Link, Stack, Toolbar } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useForm } from 'react-hook-form';

import { editContract } from '../../axiosClient/contractsApi';
import { IMenuOption } from '../../interfaces/IMenuOption';
import { contractApi } from '../../redux/services/contractApi';
import { useAppDispatch } from '../../redux/store';
import { RootState, useTypedSelector } from '../../redux/store';
import ControlledTextField from '../ControlledTextField';
import DropdownButton from '../DropdownButton';

const downloadMenu: IMenuOption[] = [
  {
    value: 'insights',
    label: 'Insights as Excel',
    startIcon: <ArticleOutlined />,
  },
];

interface Props {
  canDownload?: boolean;
  goBackUrl?: string;
  renderAction?: () => React.ReactNode;
}

const DraftHeader: React.FC<Props> = ({
  canDownload = false,
  renderAction,
  goBackUrl,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { contract } = useTypedSelector((state: RootState) => state.contract);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    resetField,
    setValue,
  } = useForm();

  const { mutate: updateContract } = useMutation({
    mutationFn: editContract,
    onSuccess: () => {
      enqueueSnackbar('Contract name updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      dispatch(
        contractApi.endpoints.getContract.initiate(contract?.id, {
          forceRefetch: true,
        })
      );
      onCancel();
    },
    onError: (error: any) => {
      const responseData =
        error?.response?.data?.__all__?.[0] || 'Failed to update Contract name!';
      enqueueSnackbar(responseData, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const insightsTable = document.getElementById('table-to-xls-insights');

  const title = useMemo(() => {
    const file_name_array = contract?.title?.split('.');
    return file_name_array?.[0] || '';
  }, [contract]);

  useEffect(() => {
    if (title && isEdit) {
      setValue('title', title);
    }
  }, [title, isEdit]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: insightsTable,
    filename: `${title}-insights`,
    sheet: 'Insights',
  });

  const handleDownload = React.useCallback(
    async (value: string) => {
      if (value === 'insights') {
        if (insightsTable) {
          onDownload();
        }
      }
    },
    [insightsTable, onDownload]
  );

  const onCancel = () => {
    resetField('title');
    setIsEdit(false);
  };

  const onSubmit = (data: any) => {
    const payload = {
      id: contract?.id,
      body: data,
    };
    if (isDirty) updateContract(payload);
  };

  return (
    <Box>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            {goBackUrl && (
              <Link
                href={goBackUrl}
                color="riPrimary.500"
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowBackIcon fontSize="small" sx={{ marginRight: '2px' }} />
                Close
              </Link>
            )}
          </Box>
          <Stack direction="row" alignItems="center" flex={1} justifyContent="center">
            {isEdit ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ControlledTextField
                    name="title"
                    control={control}
                    label="Contract Name"
                    fullWidth
                  />
                  <IconButton onClick={onCancel}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Stack>
              </form>
            ) : (
              <Button
                variant="text"
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setIsEdit(true)}
                endIcon={<EditOutlinedIcon />}
              >
                {title}
              </Button>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {canDownload && (
              <DropdownButton
                options={downloadMenu}
                startIcon={<SaveAltIcon />}
                menuHeader="Download as:"
                buttonName="Download"
                handleDropdownChange={handleDownload}
              />
            )}
            {renderAction?.()}
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
    </Box>
  );
};

export default DraftHeader;
